import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//import { strings } from '../../resources/strings';

export class TicketInfo extends Component {
    render() {
        return (
            <Container fluid className="mb-5 tariff">
                <Row className="mt-5">
                    <a href="docs/Tarifas_AVE MOBILIDADE.pdf" target="_blank">Descarregar Informação Tarifária Completa</a>
                </Row>
                <Row className="mt-5">
                    <h1>Temos sempre uma solução a pensar em si.</h1>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="darkgray">BILHETE <b>SIMPLES</b></h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido a bordo;</li>
                                <li>Permite a realização de 1 viagem no autocarro onde foi adquirido;</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="darkgray"><b>MEIO</b> BILHETE</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido a bordo;</li>
                                <li>Permite a realização de 1 viagem no autocarro onde foi adquirido;</li>
                                <li>Destina-se a crianças dos 4 aos 12 anos inclusive e adultos com idade igual ou superior a 65 anos;</li>
                                <li>Nas viagens com origem e destino na zona do centro da cidade de Guimarães aplica-se excecionalmente um valor base de 0,85€.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="red"><b>PRÉ-COMPRADO</b><br />10 VIAGENS</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido e recarregado na bilheteira;</li>
                                <li>Permite a realização de 10 viagens na origem e destino escolhidos;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro;</li>
                                <li>Desconto 10% vs bilhete simples.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="lightgray">PASSE <b>SOCIAL</b></h5>
                            <ul>
                                <li>Título <b>personalizado, pessoal e intransmissível;</b></li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Recarregado mensalmente na bilheteira ou na rede Saltpay;</li>
                                <li>Válido todos os dias do mês para o qual foi adquirido com um número ilimitado de viagens;</li>
                                <li>Permite viajar entre a origem e destino escolhidos;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro.</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 fotografia (caso não apresente Cartão de Cidadão).</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="green">PASSE <b>PESSOAS COM MOBILIDADE<br />CONDICIONADA (PPMC)</b> <small>- PASSAGEIROS</small></h5>
                            <ul>
                                <li>Destina-se a pessoas com um grau de incapacidade igual ou superior a 60% e que possuam o Cartão Municipal de Pessoa com Deficiência. Funciona nos mesmos moldes do Passe Mensal (PM), mas com desconto de 50% sobre a tarifa aplicável.</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 fotografia (caso não apresente Cartão de Cidadão).</li>
                                        <li>Declaração cartão Municipal de Pessoa com Deficiência.</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="green">PASSE <b>PESSOAS COM MOBILIDADE<br />CONDICIONADA (PPMC)</b> <small>- ACOMPANHANTE</small></h5>
                            <ul>
                                <li>Destina-se a acompanhantes de Pessoas com Mobilidade Condicionada, com um grau de incapacidade igual ou superior a 80%.</li>
                                <li>Sem custos associados;</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 fotografia (caso não apresente Cartão de Cidadão).</li>
                                        <li>Atribuição precedida de habilitação pelos Serviços Ação Social do Município.</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="blue">PASSE SUB18+TP <b>GRATUITO</b></h5>
                            <ul>
                                <li>Título <b>personalizado, pessoal e intransmissível;</b></li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Recarregado mensalmente a bordo;</li>
                                <li>Válido todos os dias do mês para o qual foi adquirido com um número ilimitado de viagens;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro;</li>
                                <li>Destina-se a jovens entre os 4 e os 18 anos (inclusive);</li>
                                <li>Renovável anualmente.</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Requisição IMT;</li>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 fotografia (caso não apresente Cartão de Cidadão).</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="blue">PASSE SUB23+TP <b>GRATUITO</b></h5>
                            <ul>
                                <li>Título <b>personalizado, pessoal e intransmissível;</b></li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Recarregado mensalmente a bordo;</li>
                                <li>Válido todos os dias do mês para o qual foi adquirido com um número ilimitado de viagens;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro;</li>
                                <li>Destina-se a jovens entre os 19 e os 23 anos (inclusive);</li>
                                <li>Renovável anualmente até ao último dia do mês que completa 24 anos.</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Requisição IMT;</li>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 fotografia (caso não apresente Cartão de Cidadão).</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}