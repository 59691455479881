import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';

export class PrivacyPolicy extends Component {
    render() {
        return(
        <Container fluid className="mb-5 px-md-6">
            
            <Row className="mt-5">
                <h1>Política de Privacidade</h1>
            </Row>

            <Row className="mt-5">
                <p>As sociedades comerciais:</p>
                <p>Transdev Mobilidade, S.A., sociedade anónima, com sede na Rua de Oslo, C.C. Londres, Loja AC 122, 4460-388 Senhora da Hora, com o número único de matrícula e pessoa coletiva 506 651 583,</p>
                <p>Auto Viação Aveirense, S.A., sociedade anónima, com sede na Rua Clube dos Galitos, 12, 3810-085 Aveiro, com o número único de matrícula e pessoa coletiva 500 038 473,</p>
                <p>Caima Transportes, S.A., sociedade anónima, com sede no Edifício do Centro Coordenador de Transportes, Av. Dr. Renato Araújo, 433-H, 3700-243 S. João da Madeira, com o número único de matrícula e pessoa coletiva 500 292 531,</p>
                <p>ETAC – Empresa de Transportes António Cunha, S.A., sociedade anónima, com sede na Avenida Fernão de Magalhães, Edifício da Rodoviária, 3001-964 Coimbra, com o número único de matrícula e pessoa coletiva 500 158 029,</p>
                <p>Minho Bus – Transportes do Minho, Sociedade Unipessoal Lda., com sede na Praça da Estação Rodoviária, 4704-510 Braga, com o número único de matrícula e pessoa coletiva 500 087 164,</p>
                <p>Rodoviária da Beira Litoral, S.A., sociedade anónima, com sede na Avenida Fernão de Magalhães – Edifício da Rodoviária, 3001-964 Coimbra, com o número único de matrícula e pessoa coletiva 502 550 414,</p>
                <p>Rodoviária da Beira Interior, S.A., sociedade anónima, com sede na Rua Portas do Sol 18 A / 18 B, 6000-261 Castelo Branco, com o número único de matrícula e pessoa coletiva 502 526 483,</p>
                <p>Rodoviária d’Entre Douro e Minho, S.A., sociedade anónima, com sede na Praça da Estação Rodoviária, 4704-510 Braga, com o número único de matrícula e pessoa coletiva 502 594 381,</p>
                <p>Transdev Douro, S.A., sociedade anónima, com sede no Largo Sacadura Cabral, n.º 32, 4630-219 Marco Canaveses, com o número único de matrícula e pessoa coletiva 500 252 173,</p>
                <p>Transdev Interior, S.A., sociedade anónima, com sede na Estrada Nacional n.º 2, 3600-194 Castro Daire, com o número único de matrícula e pessoa coletiva 500 148 775,</p>
                <p>Transdev Norte, S.A., sociedade anónima, com sede na Alameda S. Dâmaso, 82 – 1.º, 4810-286 Guimarães, com o número único de matrícula e pessoa coletiva 500 036 365,</p>
                <p>Transdev Portugal – Atividades Aeroportuárias Unipessoal, Lda, com sede na Rua de Oslo, C.C. Londres, Loja AC 122, 4460-388 Senhora da Hora, com o número único de matrícula e pessoa coletiva 515 173 606,</p>
                <p>Transdev Expressos, Unipessoal, LDA., com sede na Rua de Oslo, C.C. Londres, Loja AC 122, 4460-388 Senhora da Hora, com o número de pessoa coletiva 516028251,</p>
                <p>Partícula Andante - Sociedade de Transportes, LDA., com sede na Alameda de São Dâmaso, 82, 1.º, 4810-286 Guimarães, com o número de pessoa coletiva 516270770,</p>
                <p>Empresa de Transportes CIM Cávado, LDA., com sede na Praça da Estação Rodoviária, 4700-377 Braga, com o número de pessoa coletiva 516253000,</p>
                <p>Transdev & Avic Cávado, LDA., com sede na Praça da Estação Rodoviária, 4700-377 Braga, com número de pessoa coletiva 516253697,</p>
            </Row>

            <Row className="mt-5">
                <p>Individualmente e no seu conjunto designadas por TRANSDEV, procedem à recolha e tratamento de dados pessoais dos seus Clientes, tais como:</p>
                <ul>
                    <li>a) nome;</li>
                    <li>b) morada;</li>
                    <li>c) número de identificação fiscal;</li>
                    <li>d) número de identificação civil;</li>
                    <li>e) números dos telefones fixo e móvel;;</li>
                    <li>f) endereço de correio eletrónico (e-mail);</li>
                    <li>g) origem e destino de viagens realizadas em transporte rodoviário de passageiros.</li>
                </ul>
            </Row>

            <Row className="mt-5">
                <p>O Cliente aceita e consente, livre e expressamente, mediante ato positivo inequívoco, autorizar
                que os seus dados pessoais, solicitados ou opcionalmente fornecidos por si, sejam recolhidos e
                tratados pela TRANSDEV nos termos aqui previstos.
                A comunicação de dados pessoais não constitui, por princípio, qualquer obrigação legal, sendo, no
                entanto, necessária para que a TRANSDEV proceda à divulgação e promoção de atividades de
                promoção, informação e passatempos respeitantes à atividade de transporte público de
                passageiros. Exclui-se do anteriormente disposto a comunicação dos dados pessoais necessária à
                emissão dos títulos de transporte nominativos que sejam adquiridos, uma vez que os mesmos são
                necessários ao cumprimento do contrato de transporte.
                O tratamento dos dados pessoais dos Clientes da TRANSDEV é da responsabilidade desta e
                consiste na operação ou no conjunto de operações efetuados sobre aqueles dados pessoais, por
                meios automatizados ou não automatizados.</p>
            </Row>

            <Row className="mt-5">
                <p>De acordo com o Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de
                abril de 2016, todos os Clientes da TRANSDEV têm o direito de:</p>
                <ul>
                    <li>a) a qualquer momento, retirar o consentimento para o tratamento dos dados pessoais;</li>
                    <li>b) proceder ou solicitar a correção de qualquer dado pessoal que o Titular dos dados pessoais tenha fornecido;</li>
                    <li>c) aceder aos mesmos a qualquer momento;</li>
                    <li>d) opor-se a que os mesmos sejam utilizados para efeitos de marketing e/ou publicitários;</li>
                    <li>e) solicitar a portabilidade dos dados pessoais, para a entidade que, para esse efeito indicar;</li>
                    <li>f) solicitar o apagamento de todos os dados pessoais que tenha fornecido pelo presente meio, e que não sejam necessários para o cumprimento de quaisquer obrigações legais pela entidade responsável pela respetiva recolha;</li>
                    <li>g) ser informado pela TRANSDEV se esta, eventualmente, tiver intenção de proceder ao tratamento dos dados pessoais para outros fins que não os que pelo presente é dado consentimento.</li>
                </ul>
            </Row>

            <Row className="mt-5">
                <p>Para quaisquer informações, assim como para o exercício dos direitos que lhe são legalmente
                conferidos, o Cliente da TRANSDEV deve contactar o Responsável pelo Tratamento através do email com o endereço privacidade@transdev.pt. Para o mesmo efeito, poderá aceder ao sítio na
                        internet da TRANSDEV, através do link <a href="https://www.transdev.pt/apoio-ao-cliente" target="_blank" rel="noopener noreferrer">https://www.transdev.pt/apoio-ao-cliente</a>.</p>
            </Row>

            <Row className="mt-5">
                <p>Os dados pessoais recolhidos e registados podem ser comunicados a outras sociedades de direito
                português que laboram em território nacional sob a marca Transdev, assim como à sociedade de
                direito francês TRANSDEV GROUP, S.A. A comunicação dos dados pessoais recolhidos, se se
                verificar, será efetuada com estrito respeito do dever de confidencialidade e do princípio da
                finalidade que presidiu à sua recolha.</p>
                <p>Os dados pessoais podem ser partilhados com entidades que não estejam localizadas no Espaço
                Económico Europeu, mas que estão obrigadas a respeitar a Regulamentação Europeia sobre
                Proteção de Dados Pessoais, nomeadamente o Regulamento (UE) 2016/679 do Parlamento
                Europeu e do Conselho, de 27 de abril de 2016.</p>
                <p>Os dados pessoais podem ser objeto de tratamento automatizado, ou seja, da criação de um perfil
                para definir as preferências pessoais e comportamentos do seu Titular, de forma a adequar
                quaisquer informações publicitárias e/ou campanhas de marketing a esses dados.</p>
                <p>Sem prejuízo dos direitos que antecedem, os dados pessoais serão armazenados por tempo
                indeterminado.</p>
            </Row>

            <Row className="mt-5">
                <p>A TRANSDEV garante a proteção dos seus dados pessoais contra qualquer perda, destruição,
                alteração, acesso ou utilização indevida ou não autorizada adotando para o efeito todas as
                medidas legalmente exigidas para o efeito. No entanto, e dado que a internet pode não constituir
                um lugar seguro de comunicação, desde já alertamos que a informação veiculada através da
                internet poderá ser intercetada por terceiros.</p>
                <p>O Titular dos dados pessoais é também plena e claramente informado que poderá apresentar
                qualquer reclamação, quanto à violação dos seus direitos, junto da Comissão Nacional de Proteção
                de Dados.</p>
                <p>A TRANSDEV designou Encarregado de Proteção de Dados, nos termos da legislação em vigor.
                Pode entrar em contacto com o Encarregado de Proteção de Dados a todo o momento para o
                seguinte endereço de correio eletrónico: dpo.privacidade@transdev.pt.</p>
                <p>A TRANSDEV poderá, unilateralmente e a qualquer momento, alterar, modificar, aditar ou retificar
                a presente política de proteção de dados e prestação de consentimento, sem necessidade de
                qualquer aviso prévio.</p>
            </Row>

            <Row className="mt-5">
                <p>As sociedades Transdev,</p>
            </Row>
        </Container>
        );
    }
}